<!--
    Used to display a readonly text field with a resolved address (If address exists).
    Example:
    <reverse-geocoding-field
      :lat="45.814666"
      :lng="15.861434"
      :vehicle-id="1"
      :loading-indicator-alignment="'left'" <- optional, default center, used to position the loading indicator inside parent element
      :text-alignment="left'"  <- optional, default center, used to position the text position inside parent element
      :retry="false" <- optional, default false, used to define if the reverse geocoding should be retried after a failed attempt once
      :loopRetry="false" <- optional, default false, used to define if the reverse geocoding should be retried after a failed attempt forever
    />
    TODO: Potentially add multiple style properties to the component (dynamic would be best) to be controlled by parent component.
-->

<template>
  <v-layout
    align-center
    class="reverse-geocoding-field"
  >
    <v-layout
      v-if="resolvedAddress"
      :class="textPosition"
    >
      <div>
        {{ resolvedAddress }}
      </div>
    </v-layout>
    <v-layout
      v-if="loading"
      :class="loadingPosition"
    >
      <v-progress-circular
        align-center
        class="ml-2"
        indeterminate
        width="2"
        size="15"
      />
    </v-layout>
  </v-layout>
</template>

<script>
import { api } from '@/global/services/api'
import { createNamespacedHelpers } from 'vuex'
const {
  mapGetters, mapActions
} = createNamespacedHelpers('satellite-tracking/tracking-history')

export default {
  name: 'ReverseGeocodingField',

  props: {
    vehicleId: {
      type: Number,
      default: null
    },
    withUserLocations: {
      type: Boolean,
      default: false
    },
    latLng: {
      type: Object,
      required: true,
      validator (value) {
        return value.lat && value.lng
      }
    },
    address: {
      type: String,
      default: null
    },
    loopRetry: {
      type: Boolean,
      default: false
    },
    retry: {
      type: Boolean,
      default: false
    },
    loadingIndicatorAlignment: {
      type: String,
      default: 'center',
      validator (value) {
        return ['left', 'center', 'right'].includes(value)
      }
    },
    textAlignment: {
      type: String,
      default: 'center',
      validator (value) {
        return ['left', 'center', 'right'].includes(value)
      }
    },
    trackingHistoryConfig: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      resolvedAddress: null,
      loading: true,
      loadingPosition: 'justify-center',
      textPosition: 'text-center'
    }
  },

  computed: {
    ...mapGetters([
      'geolocations'
    ])
  },

  mounted () {
    this.resolveLoadingPosition()
    this.resolveTextPosition()
    this.resolveAddress(false)
  },

  methods: {
    ...mapActions([
      'setGeolocations'
    ]),

    async resolveAddress (isRetry) {
      this.resolvedAddress = null

      this.loading = true

      if (this.address !== ' ' && this.address != null && typeof this.address !== 'undefined') {
        this.resolvedAddress = this.address
        this.loading = false
        return
      }

      if (this.trackingHistoryConfig) {
        for (const value of this.geolocations) {
          if (value.lng === this.latLng.lng && value.lat === this.latLng.lat) {
            if (value.address != null) {
              this.resolvedAddress = value.address
              this.loading = false
              return true
            }
          }
        }
      }

      const params = {
        lat: this.latLng.lat,
        lng: this.latLng.lng
      }

      if (this.vehicleId) {
        params.vehicleId = this.vehicleId
      }

      if (this.withUserLocations) {
        params.withUserLocations = true
      }

      try {
        const response = await api()['satellite-tracking'].get('reverse-geocode', params)

        this.$nextTick(() => {
          if (this.shouldTryAgain(response, isRetry)) {
            this.resolveAddress(true)
          }
          else {
            this.resolvedAddress = response.data.address
            if (this.trackingHistoryConfig && this.geolocations.length < 1000) {
              this.setGeolocations({
                lat: this.latLng.lat,
                lng: this.latLng.lng,
                address: response.data.address
              })
            }
          }
        })
      }
      catch (error) {
        console.log('Reverse geocoding error occurred in global reverse geocoding component: ', error)
      }

      this.loading = false
    },

    // Handle retry logic
    shouldTryAgain (response, isRetry) {
      if (!response.data.address && !isRetry && this.retry) {
        return true
      }

      return !response.data.address && this.loopRetry
    },

    // Resolve position of loading indicator
    resolveLoadingPosition () {
      switch (this.loadingIndicatorAlignment) {
        case 'left':
          this.loadingPosition = 'justify-start'
          break
        case 'right':
          this.loadingPosition = 'justify-end'
          break
        default:
          this.loadingPosition = 'justify-center'
      }
    },

    // Resolve position of text
    resolveTextPosition () {
      switch (this.textAlignment) {
        case 'left':
          this.textPosition = 'justify-start'
          break
        case 'right':
          this.textPosition = 'justify-end'
          break
        default:
          this.textPosition = 'justify-center'
      }
    }
  }
}

</script>
